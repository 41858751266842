import {useContext, useEffect, useState} from "react";
import {useLocation, useParams} from "react-router-dom";
import ReactGA from "react-ga4";
import {CompetitionContext} from "@contexts/CompetitionContext";
const useGaTracker = () => {
    const params = useParams();
    const competition = useContext(CompetitionContext)
    const location = useLocation();
    const embedded = (process.env.REACT_APP_MODE !== "default");
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        if (Object.hasOwn(params, 'slug') || embedded === true) {
            if(competition.metadata.analytics.google?.enabled === true) {
                const measurementId = competition.metadata.analytics.google.measurementId;
                if(measurementId != null) {
                    /** TODO: allow for custom multiple measurement IDs **/
                    const trackingIds = [{
                        trackingId: measurementId,
                    }]
                    if (competition.slug.startsWith('newscorp')) {
                        trackingIds.push({
                            trackingId: 'AW-10815543187', // NewsCorp
                        })
                        trackingIds.push({
                            trackingId: 'G-GXHQ2DF4PD', // RBU (Win with News)
                        })
                    }
                    ReactGA.initialize(trackingIds)
                    setInitialized(true);
                }
            }
        } else {
            setInitialized(false);
        }
    }, [competition, params, embedded]);

    useEffect(() => {
        if (initialized && competition.slug) {
            const { slug } = competition;
            let page = location.pathname.replace(`/${slug}/`, '')
            ReactGA.send({ hitType: "pageview", page: page + location.search });

            /** TODO: allow for custom conditional events and conversion tracking **/
            if (competition.slug.startsWith('newscorp')) {
                page = location.pathname.replace(`/`, '')
                if (page.startsWith('thankyou')) {
                    // NewsCorp GA Tracking
                    ReactGA.ga('event', 'conversion', {
                        'send_to': 'AW-10815543187/9KqECOKKy4sZEJOvoKUo',
                    });
                    console.log('AW-10815543187/9KqECOKKy4sZEJOvoKUo')
                }
            }
        }
    }, [initialized, location, competition]);
};

export default useGaTracker;
