// ----------------------------------------------------------------------

import {useCallback, useEffect, useMemo, useState} from "react";
import * as api from "@api/service/competition";
import {default as html} from "html-react-parser";
import {ImageComponent} from "@components/ImageComponent";
import {Link} from "react-router-dom";
import logoAU from "../assets/img/logo-au-drinkwise.png";
import logoNZ from "../assets/img/logo-nz-cheers.svg";

export default function LandingLayout() {
    const country = process.env.REACT_APP_COUNTRY;
    const company = process.env.REACT_APP_COMPANY || '';

    const [competitions, setCompetitions] = useState([]);
    const [loading, setLoading] = useState(true);

    // Load the competition from the API
    const fetch = useCallback(async (company) => {
        try {
            if(company !== '') {
                const response = await api.fetchAll(company);
                setCompetitions(response);
            }
        } catch (error) {
            // if here then a 404!
            setCompetitions([]);
        }
        setLoading(false);
    }, [setCompetitions]);

    useEffect(() => {
        if(company !== '') {
            fetch(company);
        }
    }, [fetch, company]);


    const openCompetitions = useMemo(() => (
        competitions.filter((competition) => (
            competition.status.toLowerCase() ==='open'
        )).sort((a, b) => a.openDateTime - b.openDateTime)
    ), [competitions]);


    const closedCompetitions = useMemo(() => (
        competitions.filter((competition) => (
            competition.status.toLowerCase() ==='closed'
        )).sort((a, b) => b.closeDateTime - a.closeDateTime).slice(0,5)
    ), [competitions]);


    if(company === '') {
        return (
            <div></div>
        );
    }

    return (
        <div className={'clover4-competition-listing'}>
            {loading && <div className={'clover4-loading'}><div className={'clover4-loading-spinner'}></div><div className={'clover4-loading-text'}>Loading...</div></div>}
            <div className={'clover-header'}></div>

            <div className={'clover-section'}>
                <div className="container-fluid">
                    <h2>Open</h2>
                        {openCompetitions.map((competition) => (
                            <div className={'row clover4-competition mb-4'} key={competition.slug}>
                                <div className={'col-12 col-md-6 clover4-col-banner'}>
                                    <Link to={competition.slug}>
                                        <ImageComponent src={competition.metadata.images.banner} />
                                    </Link>
                                </div>
                                <div className={'col-12 col-md-6 clover4-col-content'}>
                                    <div className={'row align-items-center'}>
                                        <div className={'col-8 clover4-col-title mb-4'}>
                                            <h1 className={'mb-0'}>{competition.metadata.content.page.headline}</h1>
                                        </div>
                                        <div className={'col-4 clover4-col-image mb-4'}>
                                            <ImageComponent src={competition.metadata.images.logoSecondary} />
                                        </div>
                                    </div>
                                    <div className={'row'}>
                                        <div className={'col-12'}>
                                            {html(competition.metadata.content.page.overview)}
                                        </div>
                                    </div>
                                    <Link to={competition.slug} className={'clover-btn clover-btn-primary'}>View competition</Link>
                                </div>
                            </div>
                        ))}
                    <h2>Closed</h2>
                    {closedCompetitions.map((competition) => (
                        <div className={'row clover4-competitions mb-4'} key={competition.slug}>
                            <div className={'col-12 col-md-6'}>
                                <div className={'clover-banner'}>
                                    <Link to={competition.slug}>
                                        <ImageComponent src={competition.metadata.images.banner} />
                                    </Link>
                                </div>
                            </div>
                            <div className={'col-12 col-md-6'}>
                                <div className={'row align-items-center'}>
                                    <div className={'col-8 clover4-col-title mb-4'}>
                                        <h1 className={'mb-0'}>{competition.metadata.content.page.headline}</h1>
                                    </div>
                                    <div className={'col-4 clover4-col-image mb-4'}>
                                        <ImageComponent src={competition.metadata.images.logoSecondary} />
                                    </div>
                                </div>
                                <div className={'row'}>
                                    <div className={'col-12'}>
                                        {html(competition.metadata.content.page.overview)}
                                    </div>
                                </div>
                                <Link to={competition.slug} className={'clover-btn clover-btn-primary'}>View competition</Link>
                            </div>
                        </div>
                    ))}


                </div>
            </div>
            <div className="clover-footer">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            {
                                (country === 'au') &&
                                <div className={'clover4-logo-drinkwise'}>
                                    <a href={'https://drinkwise.org.au/'} target={'_blank'} rel="noreferrer"><img src={logoAU} alt="Drinkwise" /></a>
                                </div>
                            }

                            {
                                (country === 'nz') &&
                                <div className={'clover4-logo-cheers'}>
                                    <a href={'https://cheers.org.nz/'} target={'_blank'} rel="noreferrer"><img src={logoNZ} alt="Cheers" /></a>
                                </div>
                            }
                            <p className={'mb-0 small'}>Please enjoy responsibly and do not forward to anyone under legal drinking age.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

  );
}
