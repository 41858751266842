import {lazy, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {Outlet, useLocation, useNavigate, useParams} from 'react-router-dom';
import * as api from '@api/service/competition';
import ErrorLayout from "@layouts/ErrorLayout";
import {CompetitionContext, CompetitionDispatchContext} from "@contexts/CompetitionContext";
import {Loadable} from "@components/Loadable";
import useGaTracker from "@hooks/useGaTracker";
import useMetaPixel from "@hooks/useMetaPixel";
import {STATUS_CLOSED, STATUS_OPEN, STATUS_PENDING, STATUS_PURGED} from "@config/competition";
import {PATH_COMPETITION} from "@routes/paths";
import {SettingsContext} from "@contexts/SettingsContext";

// ----------------------------------------------------------------------

export default function CompetitionLayout() {
    useGaTracker()
    useMetaPixel()
    const { settings, setSettings } = useContext(SettingsContext)
    const knownTemplates = useMemo(() => process.env.REACT_APP_TEMPLATES.split(', ') || ['default', 'single'], []);

    const params = useParams()
    const location = useLocation()
    const navigate = useNavigate()

    //if slug has been set, then use that, otherwise pull from the params
    const slug = useMemo(() => {
        return (settings.slug) ? settings.slug : params.slug;
    }, [settings, params]);
    const baseUrl = process.env.REACT_APP_HOST_API || ''

    // need to load in the competition details based on the slug here and store in context

    const competition = useContext(CompetitionContext)
    const setCompetition = useContext(CompetitionDispatchContext)

    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [initialLoad, setInitialLoad] = useState(true);

    const embedded = (settings.mode !== 'default')

    const navigationPrefix = useMemo(() => (
        (embedded !== true) ? `/${slug}` : ''
    ), [embedded, slug]);

    const pageRouteName = useMemo(() =>{
        const result = location.pathname.replace(navigationPrefix+'/', '')
        const results = result.split('/')
        //console.log(results);
        return results[0] ? results[0] : 'landing';
    }, [location, navigationPrefix])

    // Load the competition from the API
    const fetch = useCallback(async (slug) => {
        try {
            const response = await api.fetch(slug);
            //make sure we fall back to default template!
            let template = response.metadata?.theme?.template || 'default';
            if(!knownTemplates.includes(template)) {
                template = 'default'; // revert to the default template as it will always work!
            }
            response.metadata.theme.template = template;
            setCompetition(response);
        } catch (error) {
            console.error(error);
            // if here then a 404!
            setError(true);
        }
        setLoading(false);
    }, [setCompetition, knownTemplates]);

    useEffect(() => {
        if(slug !== undefined && slug !== '') {
            fetch(slug);
        }
    }, [fetch, slug]);


    // Update the age gate setting when the competition is updated
    useEffect(() => {
        const { enableAgeGate } = competition;
        if(enableAgeGate !== undefined) {
            // if the settings.ageGate != enableAgeGate  then update the value
            if((enableAgeGate !== settings.ageGate)) {
                setSettings(prevSettings => {
                    if(prevSettings.ageGate === true) { // only update if the ageGate is true
                        return {...prevSettings, ageGate: false}; // Rerender
                    }
                    // otherwise don't update
                    return prevSettings;
                });
            }
        }
    }, [competition, settings, setSettings]);

    // Push user to the landing on initial load (only when the PageRouteName is landing, otherwise go to pageRouteName)
    useEffect(() => {
        if(embedded && settings.initialized && initialLoad) {
            // only redirect if initially loading root
            if(pageRouteName !== settings.landing && pageRouteName === 'landing') {
                // console.log(`Landing: ${settings.landing}`, `PageRouteName: ${pageRouteName}`, settings);
                navigate(PATH_COMPETITION[settings.landing](navigationPrefix), {replace: true});
            }
            setInitialLoad(false);
        }
    }, [embedded, initialLoad, pageRouteName, settings, navigate, navigationPrefix]);


    useEffect(() => {
        // Redirect for Open/Pending/Closed Competitions
        // We want to allow the terms page to be accessible regardless of the status
        if (pageRouteName !== 'terms' && pageRouteName !== 'privacy' && pageRouteName !== 'winners') {
            if (pageRouteName === 'pending' || pageRouteName === 'closed') {
                if (competition.status === STATUS_OPEN) {
                    // need to redirect them to 'landing' page if competition is open
                    navigate(PATH_COMPETITION.root(navigationPrefix), {replace: true});
                } else if (competition.status === STATUS_CLOSED || competition.status === STATUS_PURGED) {
                    // need to redirect them to closed page if competition is closed
                    navigate(PATH_COMPETITION.closed(navigationPrefix), {replace: true});
                } else if (competition.status === STATUS_PENDING) {
                    // need to redirect them to pending page if competition is pending
                    navigate(PATH_COMPETITION.pending(navigationPrefix), {replace: true});
                }
            } else {
                if (competition.status === STATUS_CLOSED || competition.status === STATUS_PURGED) {
                    // need to redirect them to closed page if competition is closed
                    navigate(PATH_COMPETITION.closed(navigationPrefix), {replace: true});
                } else if (competition.status === STATUS_PENDING) {
                    // need to redirect them to pending page if competition is pending
                    navigate(PATH_COMPETITION.pending(navigationPrefix), {replace: true});
                }
            }
        }
    }, [competition, slug, navigate, navigationPrefix, pageRouteName])

    // Force trailing slash for consistency
    useEffect(() => {
        if (!location.pathname.endsWith('/')) {
            const newPathname = `${location.pathname}/`;
            navigate(newPathname);
        }
    }, [navigate, location]);

    const Layout = useMemo(
        () => Loadable(lazy(() => import(`@templates/${competition.metadata.theme.template.toLowerCase()}/Layout`))),
        [competition]
    );

    const cssUrl = useMemo(
        () => (`${baseUrl}/css/${slug}.css`),
        [slug, baseUrl]
    );

    if(error === true) {
        return (
            <ErrorLayout/>
        );
    } else {
        return (
            <>
                {loading && <div className={'clover4-loading'}><div className={'clover4-loading-spinner'}></div><div className={'clover4-loading-text'}>Loading...</div></div>}
                {loading === false &&
                    <>
                        <link rel="stylesheet" type="text/css" href={cssUrl} />
                        <Layout pageClassName={`clover4-page-${pageRouteName}`}>
                            <Outlet />
                        </Layout>
                    </>
                }
            </>
        );
    }

}
