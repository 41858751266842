import { createContext, useState } from 'react';

const initialState = {
    id: null,
    name: null,
    status: null,
    slug: null,
    timezone: null,
    country: null,
    metadata: {
        content: {},
        faq: {},
        form: {},
        images: {},
        theme: {
            template: "default"
        },
        termsConditions: {},
        winners: {},
        analytics: {
            google: {
                enabled: false,
                measurementId: null
            },
            meta: {
                enabled: false,
                pixels: []
            }
        },
        entries: {
            available: -1
        }
    }
};

const CompetitionContext = createContext(initialState);
const CompetitionDispatchContext = createContext(initialState);

function CompetitionProvider({ children }) {
    const [competition, setCompetition] = useState(initialState);
    return (
        <CompetitionContext.Provider value={competition}>
            <CompetitionDispatchContext.Provider value={setCompetition}>
                {children}
            </CompetitionDispatchContext.Provider>
        </CompetitionContext.Provider>
    );
}


export { CompetitionProvider, CompetitionContext, CompetitionDispatchContext };