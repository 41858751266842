export const PATH_ERROR = {
  page404: '/404'
};

export const PATH_COMPETITION = {
  root: (prefix) => `${prefix}/`,
  landing: (prefix) => `${prefix}/landing/`,
  about: (prefix) => `${prefix}/about/`,
  terms: (prefix) => `${prefix}/terms/`,
  faq: (prefix) => `${prefix}/faq/`,
  privacy: (prefix) => `${prefix}/privacy/`,
  enter: (prefix) => `${prefix}/enter/`,
  thankYou: (prefix) => `${prefix}/thankyou/`,
  pending: (prefix) => `${prefix}/pending/`,
  closed: (prefix) => `${prefix}/closed/`,
  winners: (prefix) => `${prefix}/winners/`,
  participatingVenues: (prefix) => `${prefix}/participating-venues/`,
  redeem: (prefix) => `${prefix}/redeem/`,
  privacyStatement: (prefix) => `privacy-statement/`,
};

export const PATH_ROOT = {
  privacyStatement: '/privacy-statement'
}
