import axios from 'axios';

// ----------------------------------------------------------------------

const baseUrl = process.env.REACT_APP_HOST_API || '';

const axiosInstance = axios.create({
  baseURL: baseUrl,
  headers: {
    Accept: 'application/json,*/*',
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest'
  },
  xsrfCookieName: 'XSRF-TOKEN',
  xsrfHeaderName: 'X-XSRF-TOKEN'
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;
