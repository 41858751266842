import axiosInstance from '@api/axios'

const rest = {
    get(url) {
        return axiosInstance
            .get(url)
            .then((response) => {
                if (response.data.success) {
                    return Promise.resolve(response.data.data);
                }
                return Promise.reject(response.data.error)

            })
            .catch((error) => Promise.reject(error))
    },
    post(url, request) {
        return axiosInstance
            .post(url, request)
            .then((response) => {
                if (response.data.success) {
                    return Promise.resolve(response.data.data)
                }
                return Promise.reject(response.data.error)
            })
            .catch((error) => Promise.reject(error))
    },
    upload(url, request, config) {
        return axiosInstance
            .post(url, request, config)
            .then((response) => {
                if (response.data.success) {
                    return Promise.resolve(response.data.data)
                }
                return Promise.reject(response.data.error)
            })
            .catch((error) => Promise.reject(error))
    },
    put(url, request) {
        return axiosInstance
            .put(url, request)
            .then((response) => {
                if (response.data.success) {
                    return Promise.resolve(response.data.data)
                }
                return Promise.reject(response.data.error)
            })
            .catch((error) => Promise.reject(error))
    }
}

export default rest
