import { Navigate, useRoutes } from 'react-router-dom';
import LandingLayout from "@layouts/LandingLayout";
import PrivacyLayout from "@layouts/PrivacyLayout";
import CompetitionLayout from "@layouts/CompetitionLayout";
import {lazy, useContext, useEffect, useMemo} from "react";
import {Loadable} from "@components/Loadable";
import {CompetitionContext} from "@contexts/CompetitionContext";
import ErrorLayout from "@layouts/ErrorLayout";
import {SettingsContext} from "@contexts/SettingsContext";

export default function Router({slug, landing, ageGate, mode}) {
  const { setSettings } = useContext(SettingsContext);
  const ageGateComponent = process.env.REACT_APP_AGE_GATE_COMPONENT || 'AgeGateClover4'
  useEffect(() => {
    setSettings({slug, landing, ageGate, ageGateComponent, mode, initialized: true});
  }, [setSettings, slug, landing, ageGate, ageGateComponent, mode]);
  const embedded = (mode !== "default");
  const competition = useContext(CompetitionContext)
  const Error = useMemo(
      () => Loadable(lazy(() => import(`@templates/${competition.metadata.theme.template.toLowerCase()}/Error`))),
      [competition]
  );

  const Landing = useMemo(
      () => Loadable(lazy(() => import(`@templates/${competition.metadata.theme.template.toLowerCase()}/pages/Landing`))),
      [competition]
  );

  const About = useMemo(
      () => Loadable(lazy(() => import(`@templates/${competition.metadata.theme.template.toLowerCase()}/pages/About`))),
      [competition]
  );

  const Terms = useMemo(
      () => Loadable(lazy(() => import(`@templates/${competition.metadata.theme.template.toLowerCase()}/pages/Terms`))),
      [competition]
  );

  const Faq = useMemo(
      () => Loadable(lazy(() => import(`@templates/${competition.metadata.theme.template.toLowerCase()}/pages/Faq`))),
      [competition]
  );

  const Privacy = useMemo(
      () => Loadable(lazy(() => import(`@templates/${competition.metadata.theme.template.toLowerCase()}/pages/Privacy`))),
      [competition]
  );

  const Enter = useMemo(
      () => Loadable(lazy(() => import(`@templates/${competition.metadata.theme.template.toLowerCase()}/pages/Enter`))),
      [competition]
  );

  const ThankYou = useMemo(
      () => Loadable(lazy(() => import(`@templates/${competition.metadata.theme.template.toLowerCase()}/pages/ThankYou`))),
      [competition]
  );

  const Pending = useMemo(
      () => Loadable(lazy(() => import(`@templates/${competition.metadata.theme.template.toLowerCase()}/pages/Pending`))),
      [competition]
  );

  const Closed = useMemo(
      () => Loadable(lazy(() => import(`@templates/${competition.metadata.theme.template.toLowerCase()}/pages/Closed`))),
      [competition]
  );

  const Winners = useMemo(
      () => Loadable(lazy(() => import(`@templates/${competition.metadata.theme.template.toLowerCase()}/pages/Winners`))),
      [competition]
  );

  const ParticipatingVenues = useMemo(
      () => Loadable(lazy(() => import(`@templates/${competition.metadata.theme.template.toLowerCase()}/pages/ParticipatingVenues`))),
      [competition]
  );

  const Redeem = useMemo(
      () => Loadable(lazy(() => import(`@templates/${competition.metadata.theme.template.toLowerCase()}/pages/Redeem`))),
      [competition]
  );

  let routes = [
    {
      path: '/',
      element: (
          <LandingLayout />
      ),
    },
    {
      path: '/privacy-statement',
      element: (
          <PrivacyLayout />
      ),
    },

    // Competition Routes
    {
      path: '/:slug',
      element: (
          <CompetitionLayout />
      ),
      children: [
        { element: <Landing />, index: true },
        { path: 'landing', element: <Landing /> },
        { path: 'about', element: <About /> },
        { path: 'terms', element: <Terms /> },
        { path: 'faq', element: <Faq /> },
        { path: 'privacy', element: <Privacy /> },
        { path: 'enter', element: <Enter /> },
        { path: 'enter/:fieldName/:fieldValue', element: <Enter /> },
        { path: 'thankyou', element: <ThankYou /> },
        { path: 'pending', element: <Pending /> },
        { path: 'closed', element: <Closed /> },
        { path: 'winners', element: <Winners /> },
        { path: 'participating-venues', element: <ParticipatingVenues /> },
        { path: 'redeem/:token', element: <Redeem /> },
        { path: '*', element: <Error /> },
      ],
    },
    {
      path: '*',
      children: [
        { path: '404', element: <ErrorLayout /> }, // globally error page
        { path: '*', element: <Navigate to="/404" /> },
      ],
    }
  ];

  if(embedded) {
    routes = [
      // Competition Routes
      {
        path: '/',
        element: (
            <CompetitionLayout />
        ),
        children: [
          { element: <Landing />, index: true },
          { path: 'landing', element: <Landing /> },
          { path: 'about', element: <About /> },
          { path: 'terms', element: <Terms /> },
          { path: 'faq', element: <Faq /> },
          { path: 'privacy', element: <Privacy /> },
          { path: 'enter', element: <Enter /> },
          { path: 'enter/:fieldName/:fieldValue', element: <Enter /> },
          { path: 'thankyou', element: <ThankYou /> },
          { path: 'pending', element: <Pending /> },
          { path: 'closed', element: <Closed /> },
          { path: 'winners', element: <Winners /> },
          { path: 'participating-venues', element: <ParticipatingVenues /> },
          { path: 'redeem/:redeem', element: <Redeem /> },
          { path: '404', element: <Error /> }, // Template error page
          { path: '*', element: <Error /> },
        ],
      },
      {
        path: '/privacy-statement',
        element: (
            <PrivacyLayout />
        ),
      },
    ];
  }

  // Fall back to 404
  routes.push({ path: '*', element: <Navigate to="/404" /> });

  return useRoutes(routes);
}
