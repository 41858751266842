// routes
import PropTypes from 'prop-types';
import Router from '@routes/index';
import {CompetitionProvider} from '@contexts/CompetitionContext';
import {SettingsProvider} from "@contexts/SettingsContext";

App.propTypes = {
    slug: PropTypes.string,
    landing: PropTypes.string,
    ageGate: PropTypes.bool,
    mode: PropTypes.string,
};

App.defaultProps = {
    slug: '',
    landing: 'landing',
    ageGate: false,
    mode: 'default'
};

function App({slug, landing, ageGate, mode}) {
    return (
        <SettingsProvider>
            <CompetitionProvider>
                <Router slug={slug} landing={landing} ageGate={ageGate} mode={mode} />
            </CompetitionProvider>
        </SettingsProvider>
    );
}
export default App;
