import {createContext, useMemo, useState} from 'react';

const initialState = {
    initialized: false,
    landing: 'landing',
    ageGate: false,
    ageGateComponent: 'AgeGateClover4',
    mode: 'default',
    country: 'au',
    slug: '',
};

const SettingsContext = createContext(initialState);

function SettingsProvider({children}) {
    const [settings, setSettings] = useState(initialState);
    const value = useMemo(
        () => ({ settings, setSettings }),
        [settings, setSettings]
    );
    return (
        <SettingsContext.Provider value={value}>
            {children}
        </SettingsContext.Provider>
    );
}


export {SettingsProvider, SettingsContext};