import logoAU from "../assets/img/logo-au-drinkwise.png";
import logoNZ from "../assets/img/logo-nz-cheers.svg";

export default function ErrorLayout() {
    const country = process.env.REACT_APP_COUNTRY;
    return (
        <div className={'clover4-competition-listing'}>
            <div className={'clover-header'}></div>

            <div className={'clover-section'}>
                <div className="container-fluid">
                    <div className={'row'}>
                        <div className={'col-12 text-center'}>
                            <h1 className={'mb-5'}>Competition not found</h1>
                        </div>
                    </div>
                </div>
            </div>

            <div className="clover-footer">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            {
                                (country === 'au') &&
                                <div className={'clover4-logo-drinkwise'}>
                                    <a href={'https://drinkwise.org.au/'} target={'_blank'} rel="noreferrer"><img src={logoAU} alt="Drinkwise" /></a>
                                </div>
                            }

                            {
                                (country === 'nz') &&
                                <div className={'clover4-logo-cheers'}>
                                    <a href={'https://cheers.org.nz/'} target={'_blank'} rel="noreferrer"><img src={logoNZ} alt="Cheers" /></a>
                                </div>
                            }
                            <p className={'mb-0 small'}>Please enjoy responsibly and do not forward to anyone under legal drinking age.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  );
}
