// ----------------------------------------------------------------------
import logoAU from "../assets/img/logo-au-drinkwise.png";
import logoNZ from "../assets/img/logo-nz-cheers.svg";

export default function PrivacyLayout() {
    const country = process.env.REACT_APP_COUNTRY || 'au'; //default to AU if nothing provided
    return (
        <div className={'clover4-competition-listing'}>
            <div className={'clover-header'}></div>
            <div className={'clover-section'}>
                <div className="container-fluid">
                    <div className={'row'}>
                        <div className={'col-12'}>
                            <h1 className={'mb-4'}>Privacy Statement</h1>
                            <p className={'mb-5'}>Your Personal Information is being collected by Pernod Ricard Winemakers Pty Ltd (the Promoter) for the purposes of competition entry, club membership, customer support, consumer insights, and if relevant, product purchase and billing. With your consent, the Promoter may use your personal information for direct marketing. If you fail to provide the Personal Information that is being requested you may not be able to enter a competition, receive a prize, purchase products, deal with your complaint or receive marketing information (as applicable). The Promoter may also share your Personal Information with other companies or individuals who assist us in providing products or services or who perform functions on its behalf (such as direct marking companies, mailing houses, couriers, consultants and service providers).   If the Promoter is to disclose information to an overseas recipient it is likely to be to one of the countries identified in its Privacy Policy. The Privacy Policy explains how you may access or seek correction to Personal Information the Promoter holds and how to make a complaint and is available at <a href="https://www.pernod-ricard-winemakers.com/privacy-policy/" target="_blank" rel="noreferrer">https://www.pernod-ricard-winemakers.com/privacy-policy/</a></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="clover-footer">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            {
                                (country === 'au') &&
                                <div className={'clover4-logo-drinkwise'}>
                                    <a href={'https://drinkwise.org.au/'} target={'_blank'} rel="noreferrer"><img src={logoAU} alt="Drinkwise" /></a>
                                </div>
                            }

                            {
                                (country === 'nz') &&
                                <div className={'clover4-logo-cheers'}>
                                    <a href={'https://cheers.org.nz/'} target={'_blank'} rel="noreferrer"><img src={logoNZ} alt="Cheers" /></a>
                                </div>
                            }
                            <p className={'mb-0 small'}>Please enjoy responsibly and do not forward to anyone under legal drinking age.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  );
}
