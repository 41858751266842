import {useContext, useEffect} from "react";
import {useLocation, useParams} from "react-router-dom";
import {CompetitionContext} from "@contexts/CompetitionContext";
import ReactPixel from "react-facebook-pixel";

const useMetaPixel = () => {
    const params = useParams();
    const embedded = (process.env.REACT_APP_MODE !== "default");
    const competition = useContext(CompetitionContext)
    const location = useLocation();
    useEffect(() => {
        if (Object.hasOwn(params, 'slug') || embedded === true) {
            if(competition.metadata.analytics.meta?.enabled === true) {
                const pixels = competition.metadata.analytics.meta.pixels;
                pixels.forEach((pixel) => {
                    ReactPixel.init(pixel);
                    ReactPixel.pageView();
                });
            }
            const { slug } = competition;
            let page = location.pathname.replace(`/${slug}/`, '')
            /** TODO: allow for custom multiple pixel tracking on different pages **/
            if (competition.slug && competition.slug.startsWith('newscorp')) {
                page = location.pathname.replace(`/`, '')
                if (page === '') {
                    // NewsCorp Pixel - Landing
                    ReactPixel.init('1344330176229542');
                    ReactPixel.pageView();
                    console.log('1344330176229542')
                }
                if (page.startsWith('thankyou')) {
                    // NewsCorp Pixel - Thankyou
                    ReactPixel.init('751520750224559');
                    ReactPixel.pageView();
                    console.log('751520750224559')
                }
            }
        }
    }, [competition, params, embedded, location]);

};

export default useMetaPixel;
